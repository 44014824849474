'use client'
// import Image from "next/image";

import FeatImage01 from "../images/information-extraction.svg";
import FeatImage02 from "../images/employee-onboarding.svg";
import FeatImage03 from "../images/ticket-solving.svg";
import FeatImage04 from "../images/form-filling.svg";
import FeatImage05 from "../images/contradiction-analysis.svg";
import FeatImage06 from "../images/personal-chatbots.svg";
// import Link from "next/link";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import handleViewport, { InjectedViewportProps } from "react-in-viewport";

const Typing = (props: InjectedViewportProps<HTMLDivElement>) => {
  const { inViewport, forwardedRef, enterCount } = props;
  if (inViewport && enterCount === 1) {
      return (
        <Typed strings={["{Unlimited Solutions"]} typeSpeed={80} startDelay={100} cursorChar="}"/>
      )
  }
  return (
      <div ref={forwardedRef}>
      </div>
  );
};

const ViewportBlock = handleViewport(Typing, /** options: {}, config: {} **/);

export default function Zigzag() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16 mt-8 mb-16">
            {/* <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">Reach goals that matter</div> */}
            <h1 className="h2 mb-6 text-gray-50" data-aos="fade-out">
              One Platform <br />
              <ViewportBlock />
              {/* <Typed strings={["{Unlimited Solutions"]} typeSpeed={80} startDelay={100} cursorChar="}"/> */}
            </h1>
            <p className="text-xl text-gray-300">
              Find the information you need fast and reliable. Update your
              knowledge database whenever you need. Track information sources
              and approve changes.
            </p>
          </div>

          {/* Items */}
          <div className="grid gap-20">
            {/* 1st item */}
            <div
              className="md:grid md:grid-cols-12 md:gap-6 items-center"
              id="first-section"
            >
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl bg-gray-50 p-8 rounded-lg h-[420px]"
                data-aos="fade-up"
              >
                <img
                  className="max-w-full mx-auto md:max-w-none h-auto"
                  src={FeatImage01}
                  width={420}
                  height={420}
                  alt="Features 01"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:order-1"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pl-16">
                  {/* <div className="font-architects-daughter text-xl text-purple-600 mb-2">More speed. Less spend</div> */}
                  <h3 className="h3 mb-3 font-semibold text-gray-100">
                    Information Extraction
                  </h3>
                  <div className="inline-flex text-sm font-semibold py-1 px-4 text-gray-200 bg-gradient-to-r from-[#6B1DB9] to-[#9C23D0] rounded-full mb-8">
                    Available
                  </div>
                  <p className="text-xl text-gray-300 mb-4">
                    Search through vast amounts of textual information in
                    seconds. Ask a question, get your answer - it's that simple!
                  </p>
                  <ul className="text-lg text-gray-300 -mb-2 mt-10">
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Explanation</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Source</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Date</span>
                    </li>
                    <li className="flex items-center">
                      <Link
                        to="/newsletter"
                        className="text-fuchsia-300 font-semibold"
                      >
                        Learn More
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 2nd item */}
            <div
              className="md:grid md:grid-cols-12 md:gap-6 items-center"
              id="second-section"
            >
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl bg-gray-50 p-8 rounded-lg md:order-1"
                data-aos="fade-up"
              >
                <img
                  className="max-w-full mx-auto md:max-w-none h-[356px]"
                  src={FeatImage02}
                  width={420}
                  height={420}
                  alt="Features 01"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pl-16">
                  {/* <div className="font-architects-daughter text-xl text-purple-600 mb-2">More speed. Less spend</div> */}
                  <h3 className="h3 mb-3 font-semibold text-gray-100">
                    Employee Onboarding
                  </h3>
                  <div className="inline-flex text-sm font-semibold py-1 px-4 text-gray-200 bg-gradient-to-r from-[#6B1DB9] to-[#9C23D0] rounded-full mb-8">
                    Coming Soon
                  </div>
                  <p className="text-xl text-gray-300 mb-4">
                    Assign expert roles in different domains for easier
                    mentorship. Approve answers to streamline information
                    sharing.
                  </p>
                  <ul className="text-lg text-gray-300 -mb-2 mt-10">
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Verified Information</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Expert Answers</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Entry Log</span>
                    </li>
                    <li className="flex items-center">
                      <Link
                        to="/newsletter"
                        className="text-fuchsia-300 font-semibold"
                      >
                        Learn More
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 3rd item */}
            <div
              className="md:grid md:grid-cols-12 md:gap-6 items-center"
              id="third-section"
            >
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl bg-gray-50 p-8 rounded-lg"
                data-aos="fade-up"
              >
                <img
                  className="max-w-full mx-auto md:max-w-none h-[356px]"
                  src={FeatImage03}
                  width={420}
                  height={420}
                  alt="Features 01"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:order-1"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pl-16">
                  {/* <div className="font-architects-daughter text-xl text-purple-600 mb-2">More speed. Less spend</div> */}
                  <h3 className="h3 mb-3 font-semibold text-gray-100">
                    Ticket Resolution
                  </h3>
                  <div className="inline-flex text-sm font-semibold py-1 px-4 text-gray-200 bg-gradient-to-r from-[#6B1DB9] to-[#9C23D0] rounded-full mb-8">
                    Coming Soon
                  </div>
                  <p className="text-xl text-gray-300 mb-4">
                    Resolve customer support tickets at lightning speed by relying on internal documents, manuals or even previously solved tickets!
                  </p>
                  <ul className="text-lg text-gray-300 -mb-2 mt-10">
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Quickly Process Tickets</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Find the Correct Solution</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Multiple Domains</span>
                    </li>
                    <li className="flex items-center">
                      <Link
                        to="/newsletter"
                        className="text-fuchsia-300 font-semibold"
                      >
                        Learn More
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 4th item */}
            <div
              className="md:grid md:grid-cols-12 md:gap-6 items-center"
              id="fourth-section"
            >
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl bg-gray-50 p-8 rounded-lg md:order-1"
                data-aos="fade-up"
              >
                <img
                  className="max-w-full mx-auto md:max-w-none h-[356px]"
                  src={FeatImage04}
                  width={420}
                  height={420}
                  alt="Features 01"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pl-16">
                  {/* <div className="font-architects-daughter text-xl text-purple-600 mb-2">More speed. Less spend</div> */}
                  <h3 className="h3 mb-3 font-semibold text-gray-100">
                    Form Completion
                  </h3>
                  <div className="inline-flex text-sm font-semibold py-1 px-4 text-gray-200 bg-gradient-to-r from-[#6B1DB9] to-[#9C23D0] rounded-full mb-8">
                    Coming Soon
                  </div>
                  <p className="text-xl text-gray-300 mb-4">
                    Automate your workflow with our form copilot! Quickly and reliably extract information from the relevant documents.
                  </p>
                  <ul className="text-lg text-gray-300 -mb-2 mt-10">
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Quick</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Correct</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Reliable</span>
                    </li>
                    <li className="flex items-center">
                      <Link
                        to="/newsletter"
                        className="text-fuchsia-300 font-semibold"
                      >
                        Learn More
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 5th item */}
            <div
              className="md:grid md:grid-cols-12 md:gap-6 items-center"
              id="fifth-section"
            >
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl bg-gray-50 p-8 rounded-lg"
                data-aos="fade-up"
              >
                <img
                  className="max-w-full mx-auto md:max-w-none h-[356px]"
                  src={FeatImage05}
                  width={420}
                  height={420}
                  alt="Features 01"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:order-1"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pl-16">
                  <h3 className="h3 mb-3 font-semibold text-gray-100">
                    Contradiction Analysis
                  </h3>
                  <div className="inline-flex text-sm font-semibold py-1 px-4 text-gray-200 bg-gradient-to-r from-[#6B1DB9] to-[#9C23D0] rounded-full mb-8">
                    Coming Soon
                  </div>
                  <p className="text-xl text-gray-300 mb-4">
                    Find, edit, and delete inaccurate information with ease and confidence! Including closed-factuality testing and topic categorization.
                  </p>
                  <ul className="text-lg text-gray-300 -mb-2 mt-10">
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Find Inaccuracies</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Trust Your Database</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Update Your Documentation</span>
                    </li>
                    <li className="flex items-center">
                      <Link
                        to="/newsletter"
                        className="text-fuchsia-300 font-semibold"
                      >
                        Learn More
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 3rd item */}
            <div
              className="md:grid md:grid-cols-12 md:gap-6 items-center"
              id="sixth-section"
            >
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl bg-gray-50 p-8 rounded-lg md:order-1"
                data-aos="fade-up"
              >
                <img
                  className="max-w-full mx-auto md:max-w-none h-[356px]"
                  src={FeatImage06}
                  width={420}
                  height={420}
                  alt="Features 01"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pl-16">
                  <h3 className="h3 mb-3 font-semibold text-gray-100">
                    Personalized Chatbots
                  </h3>
                  <div className="inline-flex text-sm font-semibold py-1 px-4 text-gray-200 bg-gradient-to-r from-[#6B1DB9] to-[#9C23D0] rounded-full mb-8">
                    Coming Soon
                  </div>
                  <p className="text-xl text-gray-300 mb-4">
                  Make use of our framework for language model deployment to integrate small language models into your pipeline.
                  </p>
                  <ul className="text-lg text-gray-300 -mb-2 mt-10">
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Versatile</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Locally-hosted</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Cost-effective</span>
                    </li>
                    <li className="flex items-center">
                      <Link
                        to="/newsletter"
                        className="text-fuchsia-300 font-semibold"
                      >
                        Learn More
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
