'use client'
import atomIcon from "../images/atom.svg";
import databaseIcon from "../images/database.svg";
import shieldLockIcon from "../images/shield-lock.svg";
import Typed from "react-typed";
// import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import handleViewport, { InjectedViewportProps } from 'react-in-viewport';

const Typing = (props: InjectedViewportProps<HTMLDivElement>) => {
  const { inViewport, forwardedRef, enterCount } = props;
  if (inViewport && enterCount === 1) {
      return (
        <Typed strings={["{Use Cases"]} typeSpeed={80} startDelay={100} cursorChar="}"/>
      )
  }
  return (
      <div ref={forwardedRef}>
      </div>
  );
};

const ViewportBlock = handleViewport(Typing, /** options: {}, config: {} **/);


export default function Features() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6" id="features">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20 text-gray-50">
            <h2 className="h2 mb-4" data-aos="fade-up">
              {/* Many Pathways to Knowledge <br />
              {/* {"{"}One Universal Guide{"}"} */}
              {/* <Typed strings={["{One Universal Guide"]} typeSpeed={80} startDelay={100} cursorChar="}"/> */}
              We Adapt to Our Environment <br />
              {/* {"{"}One Universal Guide{"}"} */}
              <Typed strings={["{Our Models Should Too"]} typeSpeed={80} startDelay={100} cursorChar="}"/>
            </h2>
            {/* <p className="text-xl text-gray-400">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit anim id est laborum.
            </p> */}
          </div>

          {/* Items */}
          <div
            className="max-w-sm mx-auto grid gap-8 md:grid-cols-4 lg:grid-cols-3 lg:gap-16 items-center md:max-w-2xl lg:max-w-none mb-8"
            data-aos-id-blocks
          >
            {/* 1st item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              {/* <svg
                className="w-16 h-16 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              > */}
              <div className="relative w-16 h-16 mb-4 flex flex-col items-center bg-[#6B1DB9] justify-center rounded-full">
                {/* <rect
                  className="fill-current text-purple-600"
                  width="64"
                  height="64"
                  rx="32"
                /> */}
                {/* <path
                  className="stroke-current text-purple-100"
                  d="M30 39.313l-4.18 2.197L27 34.628l-5-4.874 6.91-1.004L32 22.49l3.09 6.26L42 29.754l-3 2.924"
                  strokeLinecap="square"
                  strokeWidth="2"
                  fill="none"
                  fillRule="evenodd"
                />
                <path
                  className="stroke-current text-purple-300"
                  d="M43 42h-9M43 37h-9"
                  strokeLinecap="square"
                  strokeWidth="2"
                /> */}
                <img
                  src={atomIcon}
                  alt="atom"
                  width="48"
                  height="48"
                />
              </div>
              {/* </svg> */}
              <h4 className="h4 mb-2 text-gray-50">Proprietary LLM</h4>
              {/* <div className="inline-flex text-sm font-semibold py-1 px-4 text-red-950 bg-purple-100 rounded-full mb-4">
                Available
              </div> */}
              <p className="text-lg text-gray-300 text-center">
                {/* Search through vast amounts of textual information in an
                instant. Ask a question, get your answer - it's that simple!
                Explanation and source included. */}
                Factual correctness
                <br />
                Source transparency
                <br />
                No external third party API-s
              </p>
              {/* <p className="cursor-pointer transition ease-in-out hover:bg-purple-100 duration-1000 text-lg text-gray-600 text-center">
                OOO
              </p> */}
            </div>

            {/* 2nd item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <div className="relative w-16 h-16 mb-4 flex flex-col items-center bg-[#6B1DB9] justify-center rounded-full">
              <img
                  src={databaseIcon}
                  alt="atom"
                  width="48"
                  height="48"
                />
              </div>
              <h4 className="h4 mb-2 text-gray-50 text-center">
                Local-Hosted Vector DB
              </h4>
              {/* <div className="inline-flex text-sm font-semibold py-1 px-4 text-red-950 bg-purple-100 rounded-full mb-4">
                Coming soon
              </div> */}
              <p className="text-lg text-gray-300 text-center">
                {/* Streamline the learning process for new employees. Approve
                answers for easy access to relevant information. Assign expert
                roles in different domains for easier mentorship. */}
                Plug-and-play self-hosting solution
                <br />
                No data leakage
                <br />
                No ML Engineers needed
              </p>
            </div>

            {/* 3rd item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <div className="relative w-16 h-16 mb-4 flex flex-col items-center bg-[#6B1DB9] justify-center rounded-full">
              <img
                  src={shieldLockIcon}
                  alt="atom"
                  width="48"
                  height="48"
                />
              </div>
              <h4 className="h4 mb-2 text-gray-50">Data Privacy Compliance</h4>
              {/* <div className="inline-flex text-sm font-semibold py-1 px-4 text-red-950 bg-purple-100 rounded-full mb-4">
                Coming soon
              </div> */}
              <p className="text-lg text-gray-300 text-center">
                {/* Sovle tickets faster with information from internal documents,
                manuals and even previously solved tickets. Check for relevance
                and date to decide the best resolution. */}
                No use of personal data 
                <br />
                No use of cookies
                <br />
                Compliant with {"{"}GDPR{"}"} and {"{"}CCPA{"}"}
              </p>
            </div>
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              {" "}
            </div>
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <h3 className="h3 mb-2 text-gray-50 mt-12 font-bold">
              <ViewportBlock onEnterViewport={() => console.log('enter')} onLeaveViewport={() => console.log('leave')} />
                {/* <Typed strings={["{Use Cases"]} typeSpeed={80} startDelay={100} cursorChar="}"/> */}
              </h3>
            </div>
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              {" "}
            </div>

            {/* 4th item */}
            <div
              className="relative flex flex-col items-center h-full justify-center"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              {/* <svg
                className="w-16 h-16 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  className="fill-current text-purple-600"
                  width="64"
                  height="64"
                  rx="32"
                />
                <g
                  transform="translate(22 21)"
                  strokeLinecap="square"
                  strokeWidth="2"
                  fill="none"
                  fillRule="evenodd"
                >
                  <path
                    className="stroke-current text-purple-100"
                    d="M17 22v-6.3a8.97 8.97 0 003-6.569A9.1 9.1 0 0011.262 0 9 9 0 002 9v1l-2 5 2 1v4a2 2 0 002 2h4a5 5 0 005-5v-5"
                  />
                  <circle
                    className="stroke-current text-purple-300"
                    cx="13"
                    cy="9"
                    r="3"
                  />
                </g>
              </svg> */}
              <HashLink className="h-full w-full flex items-center" to="#first-section">
                <span className="flex bg-gray-800 font-semibold h3 m-auto text-gray-50 text-center cursor-pointer transition ease-in hover:bg-gray-700 hover:text-[#FF2EFE] hover:border-gray-600 hover:shadow-[0_60px_60px_10px_rgba(0,0,0,0.25)] duration-300 h-full items-center p-8">
                  Information Extraction
                </span>
              </HashLink>
              {/* <div className="inline-flex text-sm font-semibold py-1 px-4 text-red-950 bg-purple-100 rounded-full mb-4">
                Coming soon
              </div> */}
              {/* <p className="text-lg text-gray-300 text-center">
                Huge amounts of forms to fill? Automate your work with our
                copilot. E-Mail copilot included.
              </p> */}
            </div>

            {/* 5th item */}
            <div
              className="relative flex flex-col items-center h-full"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <HashLink className="w-full h-full flex items-center" to="#second-section">
                <h3 className="flex font-semibold h3 m-auto text-gray-50 bg-gray-800 text-center cursor-pointer transition ease-in hover:bg-gray-700 hover:text-[#FF2EFE] hover:shadow-[0_60px_60px_10px_rgba(0,0,0,0.25)] duration-300 h-full w-full items-center p-8">
                  Employee Onboarding
                </h3>
              </HashLink>
            </div>

            {/* 6th item */}
            <div
              className="relative flex flex-col items-center h-full"
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <HashLink className="h-full w-full flex items-center justify-center" to="#third-section">
                <h3 className="flex font-semibold h3 m-auto text-gray-50 bg-gray-800 text-center cursor-pointer transition ease-in hover:bg-gray-700 hover:text-[#FF2EFE] hover:shadow-[0_60px_60px_10px_rgba(0,0,0,0.25)] duration-300 h-full items-center p-8 w-full justify-center">
                  Ticket <br />
                  Resolution
                </h3>
              </HashLink>
            </div>
            <div
              className="relative flex flex-col items-center h-full"
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <HashLink className="h-full w-full flex items-center" to="#fourth-section">
                <h3 className="flex font-semibold h3 m-auto text-gray-50 text-center bg-gray-800 cursor-pointer transition ease-in hover:bg-gray-700 hover:text-[#FF2EFE] hover:shadow-[0_60px_60px_10px_rgba(0,0,0,0.25)] duration-300 h-full items-center p-8 w-full justify-center">
                  Form <br />
                  Completion
                </h3>
              </HashLink>
            </div>
            <div
              className="relative flex flex-col items-center h-full"
              data-aos="fade-up"
              data-aos-delay="700"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <HashLink className="h-full w-full flex items-center" to="#fifth-section">
                <h3 className="flex font-semibold h3 m-auto text-gray-50 text-center cursor-pointer transition bg-gray-800 ease-in hover:bg-gray-700 hover:text-[#FF2EFE] hover:shadow-[0_60px_60px_10px_rgba(0,0,0,0.25)] duration-300 h-full items-center p-8 w-full justify-center">
                  Contradiction Analysis
                </h3>
              </HashLink>
            </div>
            <div
              className="relative flex flex-col items-center h-full"
              data-aos="fade-up"
              data-aos-delay="800"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <HashLink className="h-full w-full flex items-center" to="#sixth-section">
                <h3 className="flex bg-gray-800 font-semibold h3 m-auto text-gray-50 text-center cursor-pointer transition ease-in hover:bg-gray-700 hover:text-[#FF2EFE] p-8 hover:shadow-[0_60px_60px_10px_rgba(0,0,0,0.25)] duration-300 h-full items-center">
                  Personalized Chatbots
                </h3>
              </HashLink>
            </div> 
          </div>
        </div>
      </div>
    </section>
  );
}
