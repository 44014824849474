import Newsletter from "../components/newsletter";
import Footer from "../components/ui/footer";
import Header from "../components/ui/header";


export const metadata = {
  title: "Impressum",
  description: "More Information",
};


export default function NewsletterPage() {
  return (
    <section className="relative bg-[#1E1E1E] min-h-screen">
      <Header />
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          
            {/* Page header */}
            {/* <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
              <h1 className="h1 mb-4">Newsletter</h1>
            </div> */}
            <Newsletter />
          </div>
        </div>
      <Footer />
    </section>
  );
}
