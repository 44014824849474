import Footer from "../components/ui/footer";

import { Link } from "react-router-dom";
import Header from "../components/ui/header";

export const metadata = {
  title: "Impressum",
  description: "More Information",
};


export default function Impressum() {
  return (
    <section className="relative bg-[#1E1E1E]">
      <Header/>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Page header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20 text-gray-400">
            <h2 className="h2 mb-8">Impressum</h2>
            <p>
              XtinctAI UG <br />
              Velin Kossev <br /> 
              Tel.: +4917634379742 <br />
              team@xtinct-ai.com <br />
            </p>
            <Link to="/">
              <p className="p-4 underline text-base">xtinct-ai.com</p>
            </Link>

            <h2 className="h2 m-8">Privacy Policy</h2>
            <div className="text-justify">
              <p className="mb-2">
                At XtinctAI, accessible from https://www.xtinct-ai.com, one of
                our main priorities is the privacy of our visitors. This Privacy
                Policy document contains types of information that is collected
                and recorded by XtinctAI and how we use it.
              </p>

              <p className="mb-2">
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us. Our
                Privacy Policy was generated with the help of{" "}
                <a href="https://www.gdprprivacynotice.com/">
                  GDPR Privacy Policy Generator
                </a>
              </p>

              <h2 className="mb-2 text-lg">
                General Data Protection Regulation (GDPR)
              </h2>
              <p className="mb-2">
                We are a Data Controller of your information.
              </p>

              <p className="mb-2">
                XtinctAI UG legal basis for collecting and using the personal
                information described in this Privacy Policy depends on the
                Personal Information we collect and the specific context in
                which we collect the information:
              </p>
              <ul className="mb-2">
                <li>XtinctAI UG needs to perform a contract with you</li>
                <li>You have given XtinctAI UG permission to do so</li>
                <li>
                  Processing your personal information is in XtinctAI UG
                  legitimate interests
                </li>
                <li>XtinctAI UG needs to comply with the law</li>
              </ul>

              <p className="mb-2">
                XtinctAI UG will retain your personal information only for as
                long as is necessary for the purposes set out in this Privacy
                Policy. We will retain and use your information to the extent
                necessary to comply with our legal obligations, resolve
                disputes, and enforce our policies. By subscirbing to the
                newsletter you explicitly agree to receive e-mails from
                XtinctAI.
              </p>

              <p className="mb-2">
                If you are a resident of the European Economic Area (EEA), you
                have certain data protection rights. If you wish to be informed
                what Personal Information we hold about you and if you want it
                to be removed from our systems, please contact us.
              </p>
              <p className="mb-2">
                In certain circumstances, you have the following data protection
                rights:
              </p>
              <ul className="mb-2">
                <li>
                  The right to access, update or to delete the information we
                  have on you.
                </li>
                <li>The right of rectification.</li>
                <li>The right to object.</li>
                <li>The right of restriction.</li>
                <li>The right to data portability</li>
                <li>The right to withdraw consent</li>
              </ul>

              <h2 className="mb-2 text-lg">Log Files</h2>

              <p className="mb-2">
                XtinctAI follows a standard procedure of using log files. These
                files log visitors when they visit websites. All hosting
                companies do this and a part of hosting services' analytics. The
                information collected by log files include internet protocol
                (IP) addresses, browser type, Internet Service Provider (ISP),
                date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that
                is personally identifiable. The purpose of the information is
                for analyzing trends, administering the site, tracking users'
                movement on the website, and gathering demographic information.
              </p>

              <h2 className="mb-2 text-lg">Privacy Policies</h2>

              <p className="mb-2">
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of XtinctAI.
              </p>

              <p className="mb-2">
                Third-party ad servers or ad networks uses technologies like
                cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on XtinctAI,
                which are sent directly to users' browser. They automatically
                receive your IP address when this occurs. These technologies are
                used to measure the effectiveness of their advertising campaigns
                and/or to personalize the advertising content that you see on
                websites that you visit.
              </p>

              <p className="mb-2">
                Note that XtinctAI has no access to or control over these
                cookies that are used by third-party advertisers.
              </p>

              <h2 className="mb-2 text-lg">Third Party Privacy Policies</h2>

              <p className="mb-2">
                XtinctAI's Privacy Policy does not apply to other advertisers or
                websites. Thus, we are advising you to consult the respective
                Privacy Policies of these third-party ad servers for more
                detailed information. It may include their practices and
                instructions about how to opt-out of certain options.{" "}
              </p>

              <p className="mb-2">
                You can choose to disable cookies through your individual
                browser options. To know more detailed information about cookie
                management with specific web browsers, it can be found at the
                browsers' respective websites.
              </p>

              <h2 className="mb-2 text-lg">Children's Information</h2>

              <p className="mb-2">
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
              </p>

              <p className="mb-2">
                XtinctAI does not knowingly collect any Personal Identifiable
                Information from children under the age of 13. If you think that
                your child provided this kind of information on our website, we
                strongly encourage you to contact us immediately and we will do
                our best efforts to promptly remove such information from our
                records.
              </p>

              <h2 className="mb-2 text-lg">Online Privacy Policy Only</h2>

              <p className="mb-2">
                Our Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in XtinctAI. This
                policy is not applicable to any information collected offline or
                via channels other than this website.
              </p>

              <h2 className="mb-2 text-lg">Consent</h2>

              <p className="mb-2">
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}
