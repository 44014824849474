import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router';

import Home from './pages/home';
import NewsletterPage from './pages/newsletter';
import Impressum from './pages/impressum';


function App() {
  return (
   <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/impressum" element={<Impressum />} />
      <Route path="/newsletter" element={<NewsletterPage />} /> 
   </Routes>
  );
}

export default App;
