
import Hero from '../components/hero'
import Features from '../components/features'
import Newsletter from '../components/newsletter'
import Zigzag from '../components/zigzag'
import Header from '../components/ui/header'
import Footer from '../components/ui/footer'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'
import Adaptations from '../components/adaptations'

export const metadata = {
  title: 'XtinctAI',
  description: 'Knowledge Management Platform Powered by Large Language Models',
}

export default function Home() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  return (
    <div className='bg-[#1E1E1E]' id='home'>
      <script src="https://cdn.rawgit.com/michalsnik/aos/2.1.1/dist/aos.js"></script>
      <Header />
      <Hero />
      {/* <Adaptations /> */}
      <Features />
      <Zigzag />
      {/* <Testimonials /> */}
      <Newsletter />
      <Footer />
    </div>
  )
}
